import Flash from "./Flash";
import { useSelector } from "react-redux";
import { useState, useContext } from "react";
import Planche from "./Planche";
import Checkbox from "./Checkbox";
import ResaPage from "./ResaPage";
import Background from "../images/Img_texture-papier_seamless_4K.png"
import MobileContext from "../context/mobile";
import { GoChevronDown } from "react-icons/go";
import classNames from "classnames";

function FlashbookPage ({goToTop}) {
	const {isMobile} = useContext(MobileContext);

	const {flashsData, planchesData, planchesOrderData} = useSelector((state) => {
		return {
			flashsData: state.data.flashsData,
			planchesData: state.data.planchesData,
			planchesOrderData: state.data.planchesOrderData,
		}
	});

	const [filters, setFilters] = useState([]);
	const [selectedFlash, setSelectedFlash] = useState();
	const [filtersOpen, setFiltersOpen] = useState(false);
	const [budget, setBudget] = useState("");

	console.log(budget)

	const mobileChevronClasses = classNames(
		"text-[6vw] transition-transform duration-200",
		filtersOpen ? "rotate-0" : "rotate-180",
	);

	const renderedPlanchesData = planchesData?.map((planche) => {
		return {
			number: planche.data.planchenumber,
			flashs: planche.data.flashs.split(' ')
		}
	});
	
	const planchesOrder = planchesOrderData?.data.planche_order.split(" ");

	const renderedFlashsData = flashsData?.map((flash) => {
		return {
			number: flash.data.flashnumber[0].text,
			image: flash.data.flashimage.url,
			takenImage: flash.data.takenimage.url,
			resaimage: flash.data.flashresa.url,
			type: flash.data.type,
			description: flash.data.description[0]?.text,
			placement: flash.data.placement,
			minPrice: flash.data.minprice[0]?.text,
			minSize: flash.data.minsize[0]?.text,
			width: parseInt(flash.data.width),
			height: parseInt(flash.data.height),
			taken: flash.data.taken,
		}
	});

	const onClickFlash = (flash) => {
		setSelectedFlash(flash);
	};

	const resetSelectedFlash = () => {
		setSelectedFlash(null);
	}

	const renderedPlanches = planchesOrder?.map((number) => { 
		return renderedPlanchesData?.map((planche) => {
			if (planche.number === number) {
				return (
					<Planche>
						{planche.flashs?.map((number) => {
							return renderedFlashsData?.map((flash) => {
								let isActive = false;
									if (filters.length === 0 || filters.indexOf(flash.type) > -1)
										if (parseInt(flash.minPrice) <= budget || budget === "")
											isActive =true;
								if (parseInt(number) === parseInt(flash.number)) {
									return <Flash data={flash} isActive={isActive} isTaken={flash.taken} onClick={onClickFlash}/>
								}
								return
							})
						})}
					</Planche>
				)
			}
		})
	});

	const onClickFilterMobile = () => {
		setFiltersOpen(!filtersOpen);
	}

	const onChangeFilters = (value) => {
		if (value === "All")
			setFilters([]);
		else {
			if (filters.indexOf(value) > -1) {
				let tmp = [...filters];
				const index = tmp.indexOf(value);
				tmp.splice(index, 1);
				setFilters(tmp);
			}
			else
				setFilters([...filters, value]);
		}
	}

	const checkFilters = (value) => {
		if (value === "All") {
			if (filters.length === 0)
				return true;
			else
				return false;
		}

		if (filters.indexOf(value) > -1)
			return true;
		return false;
	}

	return (
		<div className="w-full h-fit min-h-screen flex flex-col bg-contain font-Montserrat" style={{ backgroundImage: `url(${Background})` }}>
			<div className="w-full h-fit min-h-screen flex flex-row">
				{selectedFlash != null && <ResaPage flash={selectedFlash} onClickClose={resetSelectedFlash} goToTop={goToTop}/>}
				{!isMobile && <div className="w-[20%] h-fit mt-[2%] ml-[5%] sticky top-[14%] flex flex-col">
					<p className="text-[1.1vw] mb-[7%] ml-[4%]">Un flash est un dessin <br /> unique qui ne sera tatoué qu'une fois. <br /> Si un motif est marqué d'une pastille orange, alors <br /> il a déjà été réservé.</p>
					<p className="mt-[5%] text-left text-[1.3vw] mb-[5%] font-bold">Collections</p>
					<div className="w-full h-fit text-[1.1vw] ml-[3%] mb-[0%]">
						<Checkbox text="Tous" value={checkFilters("All")} change={() => onChangeFilters("All")} margins="mt-[2.3%] mr-[3%]"/>
						<Checkbox text="Animaux" value={checkFilters("Animaux")} change={() => onChangeFilters("Animaux")} margins="mt-[2.3%] mr-[3%]"/>
						<Checkbox text="Insectes" value={checkFilters("Insectes")} change={() => onChangeFilters("Insectes")} margins="mt-[2.3%] mr-[3%]"/>
						<Checkbox text="Objets" value={checkFilters("Objets")} change={() => onChangeFilters("Objets")}  margins="mt-[2.3%] mr-[3%]"/>
						<Checkbox text="Plantes" value={checkFilters("Plantes")} change={() => onChangeFilters("Plantes")}  margins="mt-[2.3%] mr-[3%]"/>
						<Checkbox text="Mains" value={checkFilters("Mains")} change={() => onChangeFilters("Mains")} margins="mt-[2.3%] mr-[3%]"/>
						<Checkbox text="Squelettes" value={checkFilters("Squelettes")} change={() => onChangeFilters("Squelettes")}  margins="mt-[2.3%] mr-[3%]"/>
						<Checkbox text="Visages" value={checkFilters("Visages")} change={() => onChangeFilters("Visages")}  margins="mt-[2.3%] mr-[3%]"/>
						<Checkbox text="Autres" value={checkFilters("Autres")} change={() => onChangeFilters("Autres")} margins="mt-[2.3%] mr-[3%]"/>
					</div>
					<p className="mt-[5%] text-left text-[1.3vw] mb-[5%] font-bold">Budget</p>
					<input className="ml-[3%] w-[50%] mb-[50%] h-[1.5vw] border-black outline-none focus:border-[#ff9600] border-[1px] rounded py-1 px-2" type="number" value={budget} onChange={(event) => setBudget(event.target.value)} />
				</div>}
				<div className="lg:w-[96%] w-full h-fit lg:mt-[2%] mt-[3%] flex flex-col items-center pb-[3%] font-Montserrat">
					{isMobile &&
						<p className="text-[3vw] mb-[4%] px-[10%]">Un flash est un dessin unique <br /> qui ne sera tatoué qu'une fois. <br /> Si un motif est marqué d'une pastille <br />orange, alors il a déjà été réservé.</p>}
					{renderedPlanches}
				</div>
			</div>
			{isMobile && <div className="flex flex-col-reverse w-screen h-fit sticky bottom-[0%] z-10 bg-contain" style={{ backgroundImage: `url(${Background})` }}>
				{filtersOpen && 
					<div className="flex flex-col py-[2%] border-x-[3px] border-black">
					<div className="flex flex-row justify-between">
							<div className="flex flex-col w-[40%] ml-[5%] text-[5vw]">
								<Checkbox text="Tous" value={checkFilters("All")} change={() => onChangeFilters("All")} margins="mt-[5%] mr-[5%]" />
								<Checkbox text="Animaux" value={checkFilters("Animaux")} change={() => onChangeFilters("Animaux")} margins="mt-[5%] mr-[5%]" />
								<Checkbox text="Insectes" value={checkFilters("Insectes")} change={() => onChangeFilters("Insectes")} margins="mt-[5%] mr-[5%]" />
								<Checkbox text="Objets" value={checkFilters("Objets")} change={() => onChangeFilters("Objets")} margins="mt-[5%] mr-[5%]" />
								<Checkbox text="Plantes" value={checkFilters("Plantes")} change={() => onChangeFilters("Plantes")} margins="mt-[5%] mr-[5%]" />
							</div>
							<div className="flex flex-col w-[40%] mr-[5%] text-[5vw]">
								<Checkbox text="Mains" value={checkFilters("Mains")} change={() => onChangeFilters("Mains")} margins="mt-[5%] mr-[5%]" />
								<Checkbox text="Squelettes" value={checkFilters("Squelettes")} change={() => onChangeFilters("Squelettes")} margins="mt-[5%] mr-[5%]" />
								<Checkbox text="Visages" value={checkFilters("Visages")} change={() => onChangeFilters("Visages")} margins="mt-[5%] mr-[5%]" />
								<Checkbox text="Autres" value={checkFilters("Autres")} change={() => onChangeFilters("Autres")} margins="mt-[5%] mr-[5%]" />
							</div>
						</div>
						<p className="mt-[2%] text-left text-[5vw] mb-[2%] text-center">Budget</p>
						<input className="w-[60%] ml-[20%] min-h-[35px] text-[5vw] text-center border-black outline-none focus:border-[#ff9600] border-[1px] rounded py-1 px-2" type="number" value={budget} onChange={(event) => setBudget(event.target.value)} />
					</div>}
				<div className="flex flex-row items-center justify-center text-[5vw] py-[2%] cursor-pointer border-t-[3px] border-x-[3px] rounded-t border-black" onClick={onClickFilterMobile}>
					<p>Filtres</p>
					<GoChevronDown className={mobileChevronClasses} />
				</div>
			</div>}
		</div>
	)
}

export default FlashbookPage;