import { APIProvider, Map, AdvancedMarker } from "@vis.gl/react-google-maps";


function Maps () {
	const position = { lat: 48.89862954159873, lng: 2.3027331880363793 };

	return (
		<APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
			<div className="w-[20%] h-[80%]">
				<Map defaultZoom={14} defaultCenter={position} disableDefaultUI={true} mapId={process.env.REACT_APP_MAP_ID}>
					<AdvancedMarker position={position}></AdvancedMarker>
				</Map>
			</div>
		</APIProvider>
	)
}

export default Maps;