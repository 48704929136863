import Background from "../images/Img_texture-papier_seamless_4K.png"
import FAQSection from "./FAQSection";
import { useContext } from "react";
import NavigationContext from "../context/navigation";
import PDF from "../recap_cica.pdf"

function FAQPage ({goToTop}) {
	const { navigate } = useContext(NavigationContext);

	const handleClickContact = (event) => {
		if (event.metaKey || event.ctrlKey)
			return;
		event.preventDefault();

		navigate("/contact");
		goToTop();
	};

	return (
		<div className="w-full h-fit min-h-screen bg-white flex flex-col items-center pb-[3%] bg-contain font-Montserrat" style={{ backgroundImage: `url(${Background})` }}>
			<p className="lg:text-[3vw] lg:w-[70%] lg:mb-[1%] lg:mt-[3%]
							text-[9vw] mt-[5%] w-[90%] text-center font-Naeris mb-[3%] text-[#ff9600]">FOIRE AUX QUESTIONS</p>
			<p className="lg:text-[1.2vw] lg:w-[50%] lg:mr-[8%] lg:ml-[0%] lg:mb-[1%]
							text-black text-[3vw] w-[85%] ml-[12%] mb-[5%] italic mr-[4%]">Pour des renseignements plus spécifiques, tu peux <a href="https://www.instagram.com/naeris.ttt/" className="cursor-pointer text-[#ff9600]">me contacter sur Instagram</a>. <br/> Pour prendre rendez-vous, merci de passer par <span className="text-[#ff9600] cursor-pointer " onClick={handleClickContact}>la page de contact</span> !</p>
			<div className="w-screen flex flex-col items-center mt-[1%]">
				<FAQSection title="C'est quoi, concrètement, un tatouage ?">
					<p className="lg:text-[1.2vw]
									lg:w-[95%] w-full px-6 text-[3vw] my-[3%] ">Le tatouage par effraction cutanée désigne une insertion de pigments sous l'épiderme par lésion de la peau. À l'aide de petites aiguilles, le tatoueur vient déposer de l'encre sous la couche superficielle de la peau.</p>
				</FAQSection>
				<FAQSection title="À quoi dont-on s'attendre en terme de sensation ?">
					<p className="lg:text-[1.2vw]
									lg:w-[95%] w-full px-6 text-[3vw] mt-[3%]">C'est un peu comme une coupure continue, ou une très longue griffure de chat. Certaines techniques comme le remplissage ou le "shading" (ombrages) sont plutôt comme un grattement superficiel.</p>
					<p className="lg:text-[1.2vw]
									w-full px-6 font-bold text-[3vw] mt-[2%]">Où est ce que ça fait le plus mal ?</p>
					<p className="lg:text-[1.2vw]
									lg:w-[95%] w-full px-6 text-[3vw] mt-[2%] mb-[3%]">Tous les placements sont différents et tout le monde réagit différemment à la douleur. Mais en général, les emplacements les plus douloureux sont ceux où les os sont les plus exposés, et où il y a le plus de terminaisons nerveuses : cage thoracique, genoux et coudes, colonne vertébrale, dos et paume des mains, pieds. Tous les endroits plus en chair, surtout les cuisses et les bras, sont beaucoup plus supportables.</p>
				</FAQSection>
				<FAQSection title="Combien ça coûte ?">
					<p className="lg:text-[1.2vw]
									lg:w-[95%] w-full px-6 text-[3vw] mt-[3%]">Il est impossible de donner un prix pour « un tatouage », puisque le tarif de chaque motif dépend de sa taille, sa complexité, et la zone du corps où il sera tatoué. <br/> Pour élaborer votre projet, contactez votre artiste et il vous fera un devis basé sur les informations que vous lui fournirez.</p>
					<p className="lg:text-[1.2vw]
									lg:w-[95%] w-full px-6 text-[3vw] mt-[2%] mb-[3%]">
						Cela étant dit, de façon générale, le plus petit des tatouages sera facturé aux alentours de 80€ (même si ce prix peut varier selon les salons et les artistes). Ces 80€, souvent appelés tarif d'ouverture d'aiguille, servent à couvrir les frais de matériel, la préparation et aseptisation du poste de tatouage, et la prise en charge du client. <br/> Pour les projets plus complexes, par exemple un bras complet, un dos complet ou une bodysuit, les prix peuvent monter dans les milliers.</p>
				</FAQSection>
				<FAQSection title="Comment prendre rendez-vous ?">
					<p className="lg:text-[1.2vw]
									lg:w-[95%] w-full px-6 text-[3vw] mt-[3%]">La prise de rendez-vous se fait soit via <span className="text-[#ff9600] cursor-pointer " onClick={handleClickContact}>la page de contact</span>, soit en passant en personne au salon pour une consultation dans le but d'élaborer ensemble le projet. C'est toujours plus sympa et plus pratique de rencontrer le client en amont et de discuter en direct.</p>
					<p className="lg:text-[1.2vw]
									lg:w-[95%] w-full px-6 text-[3vw] mt-[2%]">Pour bloquer une date de rendez-vous, un dépôt d'acompte est nécessaire. Le montant varie selon les artistes et les projets, mais cette somme sert d'engagement de votre part et sera déduite du prix final de votre tatouage.</p>
					<p className="lg:text-[1.2vw]
									w-full px-6 font-bold text-[3vw] mt-[2%]">Quand vais-je recevoir mon dessin ?</p>
					<p className="lg:text-[1.2vw]
									lg:w-[95%] w-full px-6 text-[3vw] mt-[2%]">Chaque artiste a sa façon de procéder quant à l'envoi du motif. En général, le client reçoit une ébauche dans la semaine précédant le rendez-vous, mais certains artistes préfèrent attendre le jour-même et vous proposeront de modifier le dessin sur le moment si nécessaire.</p>
					<p className="lg:text-[1.2vw]
									w-full px-6 font-bold text-[3vw] mt-[2%]">Peut-on se faire tatouer sans rdv ?</p>
					<p className="lg:text-[1.2vw]
									lg:w-[95%] w-full px-6 text-[3vw] mt-[2%] mb-[3%]">Oui, beaucoup de salons prennent ce qu'on appelle des « walk-in ». C'est valable pour tous les petits tattoos rapides à élaborer ou les projets pré-dessinés. Pour un projet sur mesure plus complexe, mieux vaut profiter d'une séance de consultation et fixer un rendez-vous en amont.</p>
				</FAQSection>
				<FAQSection title="Comment se passe la séance ?">
					<p className="lg:text-[1.2vw]
									lg:w-[95%] w-full px-6 text-[3vw] mt-[3%]">Après la présentation du motif au client et le choix de la taille finale, le tatoueur procède à une aseptisation (nettoyage, désinfection) de la peau. Vient ensuite la pose du stencil, comme un calque sur la peau qui sert de modèle, puis le tatouage. <br /> Après chaque session, le client reçoit une explication détaillée des soins à réaliser pendant et après la cicatrisation. Enfin, le tatoueur pose un pansement temporaire.</p>
					<p className="lg:text-[1.2vw]
									w-full px-6 font-bold text-[3vw] mt-[2%]">Et ça prend combien de temps ?</p>
					<p className="lg:text-[1.2vw]
									lg:w-[95%] w-full px-6 text-[3vw] mt-[2%] mb-[3%]">Cette notion aussi dépend du motif, sa taille, son niveau de détail, de la technique de l'artiste… <br/>Par exemple, les motifs piqués à la main, comme le tebori au Japon, les méthodes traditionnelles polynésiennes, ou encore le handpoke, demanderont des sessions beaucoup plus longues que les motifs réalisés à la machine électrique.</p>
				</FAQSection>
				<FAQSection title="Que faire pendant et après la cicatrisation ?">
					<p className="lg:text-[1.2vw]
									lg:w-[95%] w-full px-6 text-[3vw] mt-[3%]" href={PDF}>Vous pouvez <a className="text-[#ff9600] cursor-pointer" href={PDF}>consulter ce récapitulatif</a> pour mieux comprendre les soins relatifs au tatouage.</p>
					<p className="lg:text-[1.2vw]
									w-full px-6 font-bold text-[3vw] mt-[2%]">Que faire en cas de retouches ?</p>
					<p className="lg:text-[1.2vw]
									lg:w-[95%] w-full px-6 text-[3vw] mt-[2%] mb-[3%]">Il arrive quelques fois que le tatouage ait besoin d'être retouché après sa cicatrisation. <br/>Dans ce cas, les reprises se font généralement un mois après la session du tatouage. Il faut en discuter directement avec votre artiste.</p>
				</FAQSection>
				<FAQSection title="Et si j'ai un coup de cœur sur un flash déjà réservé ?">
					<p className="lg:text-[1.2vw]
									lg:w-[95%] w-full px-6 text-[3vw] my-[3%] ">Il est toujours possible de penser un motif sur mesure fortement inspiré d'un flash qui a déjà été tatoué. Par exemple, on peut faire une composition similaire avec de nouveaux éléments, ou à l'inverse traiter le même sujet d'une façon légèrement différente. Quoi qu'il en soit, mieux vaut <span className="text-[#ff9600] cursor-pointer " onClick={handleClickContact}>me contacter directement</span> pour en parler ensemble et voir quelles options s'offrent à nous !</p>
				</FAQSection>
				<p className="lg:text-[2.2vw] lg:w-[70%] lg:mb-[1%] lg:mt-[3%]
							text-black text-[5vw] mt-[5%] w-[90%] font-bold mb-[3%]">À propos</p>
				<div className="w-screen flex flex-col items-center mt-[1%]">
					<FAQSection title="Qui suis-je ?">
						<p className="lg:text-[1.2vw]
									lg:w-[95%] w-full px-6 text-[3vw] my-[3%] ">Amateur de dessin depuis mon plus jeune âge, j'ai découvert l'art du tatouage à travers la musique extrême, qui me passionne depuis mon adolescence. <br/>
									<br/>
									Je me suis d'abord imaginé étudier la médecine avant de me tourner par défaut vers l'art traditionnel, puis le graphisme. Ce n'est qu'après l'obtention de ma licence (et beaucoup de questionnements) que j'ai commencé à sérieusement considérer le tatouage en tant que carrière. Entre temps, j'ai aussi travaillé dans l'encadrement d'art. Après plus de deux ans passés à élaborer mon portfolio, j'ai finalement eu l'occasion de faire mon apprentissage au cœur de Paris, avant de rejoindre L'Encanaillerie en été 2023, où je tatoue toujours aujourd'hui. <br/>
									<br/>
									Finalement, le mélange des domaines du design, de l'artisanat et des sciences que j'ai trouvé dans le tatouage correspond parfaitement aux aspirations du moi adolescent. Aujourd'hui, ça me semble un peu comme une évidence.</p>
					</FAQSection>
					<FAQSection title="Mes inspirations">
						<p className="lg:text-[1.2vw]
									lg:w-[95%] w-full px-6 text-[3vw] my-[3%]">
							J'ai toujours eu un attrait pour le traitement de l'absurde, des émotions et de la lumière. L'art baroque, le romantisme et le surréalisme sont des sources d'inspiration sans limite pour moi.<br/>
							<br/>
							J'aime créer des motifs qui à la fois plaisent et dérangent, que ce soit par leur thème ou par leur traitement. La dualité entre l'attrait pour une pièce et le questionnement qu'elle suscite, c'est un concept qui m'attire énormément.<br/>
							<br/>
							En terme de techniques, j'aime particulièrement les œuvres sculptées, brûlées ou gravées : je trouve que le travail de la texture et du support offre une dimension supplémentaire à l'œuvre. Comme dans le tatouage, au final !</p>
					</FAQSection>
				</div>
			</div>
		</div>
	)
}

export default FAQPage;