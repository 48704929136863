import Event from "./Event";
import { useSelector } from "react-redux";
import Background from "../images/img_fond_noir_seamless.png"

function Events ({goToTop}) {
	const eventsData = useSelector((state) => {
		return state.data.eventsData;
	})
	
	const renderedEventsData = eventsData?.map((event) => {
		return {
			label: event.data.city[0].text + ' ' + event.data.dates[0].text,
			value: event.data.city[0].text + ' ' + event.data.dates[0].text,
			full: event.data.full,
			city: event.data.city[0].text,
			place: event.data.place[0].text,
			dates: event.data.dates[0].text,
			startDate: new Date(event.data.startdate),
			id: event.id,
		}
	})

	const sortedEventsData = renderedEventsData?.sort((a, b) => a.startDate - b.startDate);

	const renderedEvents = sortedEventsData?.map((event) => {
		return <Event city={event.city} place={event.place} full={event.full} date={event.dates} event={event} id={event.id} goToTop={goToTop}/>
	})


	return (
		<div>
			<div className="w-full h-fit min-h-screen flex flex-col items-center pb-[3%] bg-repeat text-white font-Montserrat" style={{ backgroundImage: `url(${Background})` }}>
				<p className="lg:text-[3vw] lg:w-[70%] lg:mb-[3%] lg:mt-[3%] lg:text-center
							text-[9vw] mt-[5%] text-center w-[90%] mb-[6%] font-NaerisOutline text-[#ff9600]">ÉVÈNEMENTS</p>
				<div className="w-[80%] ml-[3%] h-fit flex flex-col items-center">
					{renderedEvents}
				</div>
			</div>
		</div>
	)
}

export default Events;