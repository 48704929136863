import classNames from "classnames";
import { changeSelectedFlash, changeSelected, changeObject } from "../store";
import { useContext } from "react";
import NavigationContext from "../context/navigation";
import { useDispatch } from "react-redux";
import { createPortal } from "react-dom";
import Background from "../images/img_fond_noir_seamless.png"
import { RxCross2 } from "react-icons/rx";

function ResaPage ({flash, onClickClose, goToTop}) {
	const { navigate } = useContext(NavigationContext);
	const dispatch = useDispatch();

	const classes = classNames(
		flash.resaimage ? "bg-contain bg-center bg-no-repeat w-[100%] lg:h-[80%] h-[55%] lg:m-[5%] mt-[5%]" : "lg:ml-[5%] ml-[0%] bg-contain bg-center bg-no-repeat w-[100%] lg:mt-[4%]",
		!flash.resaimage ? flash.height > 4 ? "lg:h-[96%] h-[50%]" : "lg:h-[70%] h-[30%] mt-[0%]" : ""
	);

	const handleClick = () => {
		dispatch(changeSelectedFlash(flash.number));
		dispatch(changeSelected("Resa"));
		dispatch(changeObject());
		navigate("/contact");
		goToTop();

	};

	return createPortal(
		<div className="lg:flex-row lg:mt-[0%]
							fixed inset-0 flex flex-col mt-[10%] bg-contain font-Montserrat z-30 text-white" style={{ backgroundImage: `url(${Background})` }}>
			<div style={{backgroundImage: `url(${flash.resaimage ? flash.resaimage : flash.image})`}} className={classes}/>
			<div className="lg:w-[50%] lg:ml-[5%]  lg:mt-[10%]
								w-[100%] h-fit">
				<div className="lg:ml-[0%]
									flex flex-col ml-[5%]">
					<p className="lg:text-[5vw]
									text-left text-[15vw]">{flash.number}.</p>
					<div className="flex flex-col">
						{flash.description != null && <p className="lg:text-[1.2vw]
														text-left text-[4vw] pr-[10%]">{flash.description}</p>}
						<br/>
						<p className="lg:text-[1.2vw]
										text-left text-[4vw]">Prix minimum : {flash.minPrice}€</p>
						<p className="lg:text-[1.2vw]
										text-left text-[4vw]">Taille minimum : {flash.minSize}</p>
						{flash.placement != null && <p className="lg:text-[1.2vw]
																	text-left text-[4vw]">Exemple de placements : {flash.placement}</p>}
					</div>
				</div>
				<button onClick={handleClick} className="lg:text-[1.3vw] lg:mt-[20%] lg:ml-[0%] lg:border-[3px]
															rounded text-[5vw] mt-[5%] ml-[5%] w-fit px-4 py-1 border-[2px] border-[#ff9600] text-[#ff9600] hover:text-white transition-colors duration-300 ease-in-out hover:bg-[#ff9600]">RÉSERVER</button>
			</div>
			<RxCross2 className="lg:right-[2%] lg:top-[10.5%] lg:text-[2vw]
									fixed right-[2%] top-[7%] font-bold text-[5vw] cursor-pointer" onClick={onClickClose}/>
		</div>,
		document.body
	)
}

export default ResaPage;